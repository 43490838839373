<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            class="text-left pr-0 py-2 cr-column"
            v-for="(header, index) in headers"
            :key="`th-${index}`"
            v-text="header.text"
          />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, cardIndex) in cardList" :key="`tr-${cardIndex}`">
          <td
            class="pr-0 py-2 cr-column"
            v-for="(header, headerIndex) in headers"
            :key="`td-${cardIndex}-${headerIndex}`"
            v-text="item[header.value]"
          />
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style scoped>
.v-data-table >>> .cr-column {
  height: inherit;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  async created() {
    this.setShowSplash(false);

    const { contactGroupId, actionObj } = this.getPrintCardListInfo;
    const { headers, sort, dir, consonant } = actionObj;

    for (const header of headers) {
      if (header.text.length > 0) {
        this.headers.push(header);
      }
    }

    this.cardList = await this.getPrintCardList({
      contactGroupId,
      sort,
      dir,
      consonant
    });
  },
  computed: {
    ...mapGetters("contactRoute", ["getPrintCardListInfo"])
  },
  watch: {
    cardList(next) {
      if (next.length > 0) {
        setTimeout(() => {
          print();
        }, 100);
      }
    }
  },
  methods: {
    ...mapActions("card", ["getPrintCardList"]),
    ...mapActions("splash", ["setShowSplash"])
  },
  data() {
    return {
      headers: [],
      cardList: []
    };
  }
};
</script>
